// Step 1: Import your component
import * as React from 'react';
import Layout from '../components/layout';
import { article } from '../styles/layout.css';
import { a, h2, p } from '../styles/global.css';
import { StaticImage } from 'gatsby-plugin-image';

// Step 2: Define your component
const AboutPage = () => (
  <Layout pageTitle="Naming">
    <article className={article}>
      <h2 className={h2}>Naming.</h2>
      <p className={p}>See the slides for my talk <a href="https://www.figma.com/file/SMo5U74YSQMoXCaslRnJ3m/Brand-Names-Why-what-and-how?" className={a}>Brand names: Why, What, and How</a> at Kyoto Startup Summer School 2021.</p>
      <p className={p}><a href="https://scrapbox.io/benfoden/Brand_names:_Why,_What,_and_How" className={a}>Read the speaking notes for Brand names</a>. If your native language is not English, I recommend using DeepL or Google Translate. The content should be roughly the same.</p>
      <p className={p}>
        <a href="https://business.tutsplus.com/tutorials/how-to-define-your-core-brand-values-and-why-you-should--cms-26301" className={a}>Find your 3 core brand values</a>.
      </p>
      <p className={p}>
        <a href="https://blog.hubspot.com/marketing/creative-exercises-better-than-brainstorming" className={a}>Try some creative exercises</a> to get your namestorm started.
      </p>
      <p className={p}>
        I don't normally do name consulting these days but I might make an exception if you have a project I am interested in.
      </p>
      <p className={p}>I am available to give talks on naming 😀</p>
    </article>
  </Layout >
);

// Step 3: Export your component
export default AboutPage;